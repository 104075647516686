<template>
  <div>
    <b-modal
      v-model="showModal"
      size="xl"
      class=""
      @hidden="closeModal"
      hide-footer
    >
      <template #modal-title>
        <h5 class="text-white font-weight-bolder m-0 py-50">
          <tabler-icon icon="BuildingBankIcon" size="18"></tabler-icon>
          <span class="text-uppercase mr-1">{{ title }}</span>
          <b-badge :variant="`${colorArea[nameArea]}`">{{ nameArea }}</b-badge>
          <slot name="filters" />
        </h5>
      </template>
      <b-container fluid class="py-1" v-if="dataAnalyzed">
        <b-row>
          <b-col cols="12" xl="12">
            <b-card>
              <!--lista de las cuentas removidas-->
              <b-card-text>
                <filter-slot
                  :filter="[]"
                  :filter-principal="filterSearch"
                  :paginate="paginate"
                  :totalRows="paginate.totalRows"
                  :start-page="paginate.startPage"
                  :to-page="paginate.toPage"
                  @reload="$refs['tableDetails'].refresh()"
                >
                  <b-table
                    slot="table"
                    ref="tableDetails"
                    class="shadow-none custom-table"
                    :items="myProvider"
                    style="box-shadow: none !important"
                    :fields="myFields"
                    :head-row-variant="'grid-custom-tickets-header'"
                    :busy.sync="isBusy"
                    sticky-header="40vh"
                    show-empty
                    responsive
                    no-provider-filtering
                    :current-page="paginate.currentPage"
                    :per-page="paginate.perPage"
                    small
                  >
                    <template #table-busy>
                      <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle mr-1" />
                        <strong>Loading ...</strong>
                      </div>
                    </template>
                    <template #cell(type_ncr)="data">
                      <div class="text-info text-center">
                        <b-badge
                          style="width: 90px"
                          :class="
                            data.item.type_ncr == 'AUTOMATIC'
                              ? 'item-magenta'
                              : 'item-purple'
                          "
                        >
                          <span>
                            {{ data.item.type_ncr }}
                          </span>
                        </b-badge>
                      </div>
                    </template>
                    <template #cell(departament)="data">
                      <div class="text-info text-center">
                        {{ nameModule(data.item.departament) }}
                      </div>
                    </template>
                    <template #cell(ncr_status)="data">
                      <b-badge
                        style="width: 110px"
                        :style="
                          data.item.status_id == 1 &&
                          'background: #fad0141f; color:#fad014 !important'
                        "
                        :variant="badgeColor(data.item.status_id)"
                      >
                        {{
                          data.item.ncr_status == "IN PROCESS"
                            ? "FAILED ATTEMPT"
                            : data.item.ncr_status
                        }}
                      </b-badge>
                    </template>
                    <template #cell(charge)="data">
                      {{ data.item.charge | formatMoney }}
                    </template>
                    <template #cell(requested_by)="data">
                      <div>
                        {{ data.item.created_by }}
                        <br />
                        {{ data.item.created_at | myGlobalDay }}
                      </div>
                    </template>
                    <template #cell(obtained_by)="data">
                      <div>
                        {{ data.item.obtained_by_name }}
                        <br />
                        {{ data.item.obtained_at | myGlobalDay }}
                      </div>
                    </template>
                    <template #cell(analysis)="data">
                      <template>
                        <feather-icon
                          :badge="totalByItem(data.item)"
                          badge-classes="bg-danger"
                          :id="`analysis_${data.index}`"
                          icon="SearchIcon"
                          :class="'cursor-pointer text-success'"
                          size="18"
                          @click="generateAnalysisNCR(data.item)"
                        />
                      </template>
                    </template>
                    <template #cell(description)="data">
                      <span
                        v-if="data.item.obs"
                        class="cursor-pointer text-primary"
                        @click="openFullTextModal(data.item)"
                      >
                        <feather-icon
                          icon="BookOpenIcon"
                          size="18"
                          v-b-tooltip.hover.right="'View Description'"
                          class="align-middle text-primary"
                        />
                      </span>
                      <span v-else> - </span>
                    </template>

                    <template #custom-foot v-if="totalItems > 0">
                      <b-tr class="bg-dark fixed-footer">
                        <b-th colspan="4" class="text-right">TOTAL ITEMS</b-th>
                        <b-th class="text-center" style="font-size: 14px">{{
                          totalItems
                        }}</b-th>
                        <th colspan="2"></th>
                      </b-tr>
                    </template>
                  </b-table>
                </filter-slot>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <ModalTabAnalysisResult
      v-if="dataNcrRequest"
      :dataNcrRequest="dataNcrRequest"
      :dataResult="dataAnalysisResult"
      :tab="typeGraphic"
      @closeModal="closeModalAnalysisResult"
    />
  </div>
</template>
<script>
import serviceReportLead from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/Efficiency/services/service-report.lead";
import ModalTabAnalysisResult from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/Efficiency/modals/ModalTabAnalysisResult.vue";
import TableAnalysisNcr from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/Efficiency/modals/TableAnalysisNcr.vue";
import Fields from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/Efficiency/data/fields.efficiency";
export default {
  name: "ModalEfficiency",
  props: {
    dataAnalyzed: {
      required: true,
      type: Array,
    },
    typeGraphic: {
      type: String,
      required: true,
    },
    nameArea: {
      type: String,
      required: true,
    },
    detailsByAccount: {
      type: Array,
      default: () => [],
    },
    analysisTotales: {
      type: Array,
      default: () => [],
    },
    accountType: {
      type: String,
      required: false,
    }
  },
  components: {
    TableAnalysisNcr,
    ModalTabAnalysisResult,
  },
  data() {
    return {
      showModal: false,
      dataLeads: {
        data: [],
        paginate: null,
      },
      isBusy: false,
      fields: Fields,
      fullText: "",
      showFullTextModal: false,
      paginate: {
        currentPage: 1,
        perPage: 50,
        totalRows: 0,
        startPage: 0,
        toPage: 0,
      },
      filterSearch: {
        type: "input",
        inputType: "text",
        placeholder: "Search by lead...",
        model: "",
      },
      showModalAnalysisResult: false,
      dataAnalysisResult: {
        removed: [],
        settlement: [],
      },
      dataNcrRequest: null,
      colorArea: {
        SUCCESS: "success",
        OBSERVED: "warning",
        TOTAL: "secondary",
      },
    };
  },
  computed: {
    title() {
      let title = "";
      switch (this.typeGraphic) {
        case "GENERAL":
          title = "Details Total Analyzed Comparison";
          break;
        case "FOR_REMOVED":
          title = "Details For Remove Comparison";
          break;
        case "FOR_SETTLEMENT":
          title = "Details For Settlement Comparison";
          break;
        case "BY_ACCOUNT_FOR_REMOVE":
        case "BY_ACCOUNT_FOR_SETTLEMENT":
          let text = this.typeGraphic.split("_")[3];
          title = `Details By Account Type ${this.accountType} (${text})`;
          break;
        default:
          title = "Details COMPARISON BETWEEN MONTHS";
          break;
      }
      return title;
    },
    myFields() {
      return this.fields.filter((x) => x.visible);
    },
    totalItems() {
      return this.analysisTotales.reduce((a, b) => {
        return a + b.total;
      }, 0);
    },
  },
  async created() {
    this.showModal = true;
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    nameModule(module) {
      if (module == "CUSTOMER SERVICE REGULAR") {
        return "CREDIT EXPERTS (REGULAR)";
      } else if (module == "CUSTOMER SERVICE DIGITAL") {
        return "CREDIT EXPERTS (DIGITAL)";
      } else {
        return module;
      }
    },
    badgeColor(badge) {
      let badgeClass = {
        1: "light-secondary",
        2: "light-warning",
        3: "light-success",
        4: "light-danger",
        5: "light-secondary",
        6: "light-warning",
      };
      return badgeClass[badge];
    },
    openFullTextModal(item) {
      this.showFullTextModal = true;
      this.fullText = item.obs;
    },
    closeFullTextModal() {
      this.showFullTextModal = false;
    },
    async generateAnalysisNCR(item) {
      try {
        const { id, ncr_request_id } = item;
        // const array1 = result_analysis_account.map((item) => item.id);
        const data_analyzed = this.dataAnalyzed.filter((x) => x.id === id);
        const params = {
          ncr_request_id,
          data_analyzed,
          detailsByAccount: this.detailsByAccount,
          tab: this.nameArea,
        };
        const dataAnalysisResult = await serviceReportLead.getAnalysisCrTab(
          params
        );
        this.dataNcrRequest = item;
        this.dataAnalysisResult = dataAnalysisResult;
      } catch (ex) {}
    },
    async myProvider(ctx) {
      try {
        const { data, paginate } =
          await serviceReportLead.getAnalyisisEfficiency({
            data_analyzed: this.dataAnalyzed,
            perPage: ctx.perPage,
            page: ctx.currentPage,
            search: this.filterSearch.model,
          });
        this.paginate.currentPage = paginate.current_page;
        this.paginate.perPage = paginate.per_page;
        this.paginate.startPage = paginate.from || 0;
        this.paginate.toPage = paginate.to || 0;
        this.paginate.totalRows = paginate.total || 0;
        return data || [];
      } catch (ex) {}
    },
    closeModalAnalysisResult() {
      this.dataNcrRequest = null;
      this.dataAnalysisResult.removed = [];
      this.dataAnalysisResult.settlement = [];
    },
    totalByItem(item) {
      item = this.analysisTotales.find((x) => x.id === item.id);
      return item ? item.total : 0;
    },
  },
  mounted() {},
};
</script>
<style scoped>
.item-purple {
  background-color: #009fbd !important;
  border-color: #009fbd !important;
}

.item-magenta {
  background-color: #89375f !important;
  border-color: #89375f !important;
}

.fixed-footer {
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: 1px solid #ddd;
}
</style>