<template>
  <div>
    <b-card title="Request NCR">
      <!--lista de las cuentas removidas-->
      <b-card-text>
        <filter-slot
          :filter="[]"
          :filter-principal="filterSearch"
          :paginate="paginate"
          :totalRows="paginate.totalRows"
          :start-page="paginate.startPage"
          :to-page="paginate.toPage"
          :no-visible-principal-filter="true"
        >
          <b-table
            slot="table"
            ref="tableDetails"
            class="shadow-none custom-table"
            :items="myProvider"
            style="box-shadow: none !important"
            :fields="fields"
            :head-row-variant="'grid-custom-tickets-header'"
            :busy.sync="is_busy"
            sticky-header="40vh"
            show-empty
            responsive
            no-provider-filtering
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            small
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(type_ncr)="data">
              <div class="text-info text-center">
                <b-badge
                  style="width: 90px"
                  :class="
                    data.item.type_ncr == 'AUTOMATIC'
                      ? 'item-magenta'
                      : 'item-purple'
                  "
                >
                  <span>
                    {{ data.item.type_ncr }}
                  </span>
                </b-badge>
              </div>
            </template>
            <template #cell(departament)="data">
              <div class="text-info text-center">
                {{ nameModule(data.item.departament) }}
              </div>
            </template>
            <template #cell(ncr_status)="data">
              <b-badge
                style="width: 110px"
                :style="
                  data.item.status_id == 1 &&
                  'background: #fad0141f; color:#fad014 !important'
                "
                :variant="badgeColor(data.item.status_id)"
              >
                {{
                  data.item.ncr_status == "IN PROCESS"
                    ? "FAILED ATTEMPT"
                    : data.item.ncr_status
                }}
              </b-badge>
            </template>
            <template #cell(charge)="data">
              {{ data.item.charge | formatMoney }}
            </template>
            <template #cell(requested_by)="data">
              <div>
                {{ data.item.created_by }}
                <br />
                {{ data.item.created_at | myGlobalDay }}
              </div>
            </template>
            <template #cell(obtained_by)="data">
              <div>
                {{ data.item.obtained_by_name }}
                <br />
                {{ data.item.obtained_at | myGlobalDay }}
              </div>
            </template>
            <template #cell(analysis)="data">
              <template>
                <feather-icon
                  :id="`analysis_${data.index}`"
                  icon="SearchIcon"
                  :class="'cursor-pointer text-success'"
                  size="18"
                  @click="generateAnalysisNCR(data.item)"
                />
              </template>
            </template>
            <template #cell(description)="data">
              <span
                v-if="data.item.obs"
                class="cursor-pointer text-primary"
                @click="openFullTextModal(data.item)"
              >
                <feather-icon
                  icon="BookOpenIcon"
                  size="18"
                  v-b-tooltip.hover.right="'View Description'"
                  class="align-middle text-primary"
                />
              </span>
              <span v-else> - </span>
            </template>
          </b-table>
        </filter-slot>
      </b-card-text>
    </b-card>
    <b-modal
      v-model="showFullTextModal"
      title="Description of the NCR Request"
      @hidden="closeFullTextModal"
      centered
      hide-footer
      size="sm"
    >
      <div v-html="fullText"></div>
    </b-modal>
  </div>
</template>
<script>
import Fields from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/Efficiency/data/fields.efficiency";
export default {
  name: "TableAnalysisNcr",
  props: {
    dataAnalysisNcr: {
      type: Array,
      required: true,
    },
    isBusy: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      fields: Fields,
      fullText: "",
      showFullTextModal: false,
      paginate: {
        currentPage: 1,
        perPage: 50,
        totalRows: 0,
        startPage: 0,
        toPage: 0,
      },
      filterSearch: {
        type: "input",
        inputType: "text",
        placeholder: "Search by lead...",
        model: "",
      },
      is_busy: false,
    };
  },
  methods: {
    nameModule(module) {
      if (module == "CUSTOMER SERVICE REGULAR") {
        return "CREDIT EXPERTS (REGULAR)";
      } else if (module == "CUSTOMER SERVICE DIGITAL") {
        return "CREDIT EXPERTS (DIGITAL)";
      } else {
        return module;
      }
    },
    badgeColor(badge) {
      let badgeClass = {
        1: "light-secondary",
        2: "light-warning",
        3: "light-success",
        4: "light-danger",
        5: "light-secondary",
        6: "light-warning",
      };
      return badgeClass[badge];
    },
    openFullTextModal(item) {
      this.showFullTextModal = true;
      this.fullText = item.obs;
    },
    closeFullTextModal() {
      this.showFullTextModal = false;
    },
    generateAnalysisNCR(item) {
      this.$emit("load_analysis", item);
    },
    myProvider(ctx){
      this.$emit("myProvider",ctx);
      return this.dataAnalysisNcr || [];
    }
  },
};
</script>
<style scoped>
.item-purple {
  background-color: #009fbd !important;
  border-color: #009fbd !important;
}

.item-magenta {
  background-color: #89375f !important;
  border-color: #89375f !important;
}
</style>