<template>
  <div>
    <filter-slot
      :filter="[]"
      :filter-principal="filterSearch"
      :paginate="paginate"
      :totalRows="paginate.totalRows"
      :start-page="paginate.startPage"
      :to-page="paginate.toPage"
      :no-visible-principal-filter="true"
    >
      <b-table
        ref="listTableSettlement"
        slot="table"
        class="shadow-none"
        :items="itemsPaginada"
        :fields="fields"
        show-empty
        no-provider-filtering
        striped
        sticky-header="50vh"
        :class="isDarkSkin ? 'custom-table-dark' : 'custom-table'"
        :head-row-variant="'grid-custom-tickets-header'"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(item)="{ index, item }">
          <div
            class="d-flex justify-content-center align-items-center custom-main-td-height"
          >
            <span>{{ index + 1 }}</span>
          </div>
          <ul class="list-unstyled mt-50" v-if="item.showDetail">
            <li
              v-for="(item, index) in textObject.length"
              :key="index"
              class="custom-heigh-detail"
            >
              <!--is empty-->
            </li>
          </ul>
        </template>
        <template #cell(account)="{ item }">
          <div
            class="d-flex justify-content-center align-items-center custom-main-td-height"
            style="max-width: 200px; position: relative"
            :class="item.deleted_at ? 'deleted_at' : ''"
          >
            <div class="d-flex flex-column w-100">
              <strong
                v-b-tooltip.hover.top="item.creditor_name"
                class="text-truncate"
                >{{ item.creditor_name }}</strong
              >
              <span>Account #:{{ item.account_number }}</span>
            </div>
          </div>
          <ul class="list-unstyled mt-50" v-if="item.showDetail">
            <li
              v-for="(item, index) in textObject"
              :key="index"
              class="custom-heigh-detail"
            >
              {{ item }}
            </li>
          </ul>
        </template>
        <template #cell(type)="{ item }">
          <div
            class="d-flex justify-content-center align-items-center custom-main-td-height"
          >
            <span :style="`color: ${textColor[item.classification_ncr_id]}`">{{
              item.classification_ncr
            }}</span>
          </div>
          <ul class="list-unstyled mt-50" v-if="item.showDetail">
            <li
              v-for="(item, index) in textObject.length"
              :key="index"
              class="custom-heigh-detail"
            >
              <!--is empty-->
            </li>
          </ul>
        </template>
        <template #cell(transunion)="{ item }">
          <div
            class="d-flex justify-content-center align-items-center custom-main-td-height"
          >
            <!-- {{ item.items[0].account_number || "-" }} -->
            <template v-if="item.items[0].type_classification != '-'">
              <span
                :class="colorBadge(item.items, 0, 1)"
                class="px-1 py-50 rounded-lg w-badge"
              >
                <tabler-icon
                  size="20"
                  :icon="
                    item.items[0].transunion_result == 1 &&
                    item.type_classification != 'LOW'
                      ? 'CircleCheckIcon'
                      : 'CircleIcon'
                  "
                />
                {{ item.items[0].type_classification_origin }}
                <template v-if="equalsClassification(item.items, 0)">
                  <span
                    v-b-tooltip.hover
                    title="current classification"
                    :class="colorBadge(item.items, 0, 2)"
                    class="change_classification rounded-lg"
                    >{{ item.items[0].type_classification }}
                    <feather-icon
                      id="working_hours"
                      icon="InfoIcon"
                      style="margin-left: 5px"
                    />
                  </span>
                </template>
              </span>
            </template>
            <template v-else>
              <img
                :src="require('@/assets/images/icons/transunion.png')"
                width="30px"
                height="30px"
                class="custom-bg-logo"
                alt="TransUnion"
              />
            </template>
          </div>
          <ul class="list-unstyled mt-50" v-if="item.showDetail">
            <li
              v-for="(key, index) in keyAccounts"
              :key="index"
              class="custom-heigh-detail"
              :id="`text_transunion_${index}`"
            >
              <template v-if="!!item.items[0][key]">
                <template v-if="fieldTypeMoney.includes(key)">
                  <template v-if="item.items[0][key] != '-'">
                    {{ item.items[0][key] | formatMoney }}
                  </template>
                  <template v-else>-</template>
                </template>
                <template v-else>
                  {{
                    item.items[0][key].length > maxLetter
                      ? `${item.items[0][key].slice(0, maxLetter)}...`
                      : item.items[0][key]
                  }}
                </template>

                <!--tooltip only greater than 'maxLetter'-->
                <b-tooltip
                  v-if="item.items[0][key].length > maxLetter"
                  :target="`text_transunion_${index}`"
                >
                  {{ item.items[0][key] }}
                </b-tooltip>
              </template>
              <template v-else>-</template>
            </li>
          </ul>
        </template>
        <template #cell(experian)="{ item }">
          <div
            class="d-flex justify-content-center align-items-center custom-main-td-height"
          >
            <!-- {{ item.items[1].account_number || "-" }} -->
            <template v-if="item.items[1].type_classification != '-'">
              <span
                :class="colorBadge(item.items, 1, 1)"
                class="px-1 py-50 rounded-lg w-badge"
              >
                <tabler-icon
                  size="20"
                  :icon="
                    item.items[1].experian_result == 1 &&
                    item.type_classification != 'LOW'
                      ? 'CircleCheckIcon'
                      : 'CircleIcon'
                  "
                />
                {{ item.items[1].type_classification_origin }}
                <template v-if="equalsClassification(item.items, 1)">
                  <span
                    :class="colorBadge(item.items, 1, 2)"
                    class="change_classification rounded-lg"
                    v-b-tooltip.hover
                    title="current classification"
                    >{{ item.items[1].type_classification }}
                    <feather-icon
                      id="working_hours"
                      icon="InfoIcon"
                      style="margin-left: 5px"
                    />
                  </span>
                </template>
              </span>
            </template>
            <template v-else>
              <img
                :src="require('@/assets/images/icons/experian.png')"
                width="30px"
                height="30px"
                class="custom-bg-logo"
                alt="Experian"
              />
            </template>
          </div>
          <ul class="list-unstyled mt-50" v-if="item.showDetail">
            <li
              v-for="(key, index) in keyAccounts"
              :key="index"
              class="custom-heigh-detail"
              :id="`text_experian_${index}`"
            >
              <template v-if="!!item.items[1][key]">
                <template v-if="fieldTypeMoney.includes(key)">
                  <template v-if="item.items[1][key] != '-'">
                    {{ item.items[1][key] | formatMoney }}
                  </template>
                  <template v-else>-</template>
                </template>
                <template v-else>
                  {{
                    item.items[1][key].length > maxLetter
                      ? `${item.items[1][key].slice(0, maxLetter)}...`
                      : item.items[1][key]
                  }}
                </template>

                <!--tooltip only greater than 'maxLetter'-->
                <b-tooltip
                  v-if="item.items[1][key].length > maxLetter"
                  :target="`text_experian_${index}`"
                >
                  {{ item.items[1][key] }}
                </b-tooltip>
              </template>
              <template v-else>-</template>
            </li>
          </ul>
        </template>
        <template #cell(equifax)="{ item }">
          <div
            class="d-flex justify-content-center align-items-center custom-main-td-height"
          >
            <!-- {{ item.items[2].account_number || "-" }} -->
            <template v-if="item.items[2].type_classification != '-'">
              <span
                :class="colorBadge(item.items, 2, 1)"
                class="px-1 py-50 rounded-lg w-badge text-left"
              >
                <tabler-icon
                  size="20"
                  :icon="
                    item.items[2].equifax_result == 1 &&
                    item.type_classification != 'LOW'
                      ? 'CircleCheckIcon'
                      : 'CircleIcon'
                  "
                />
                {{ item.items[2].type_classification_origin }}
                <template v-if="equalsClassification(item.items, 2)">
                  <span
                    v-b-tooltip.hover
                    title="current classification"
                    :class="colorBadge(item.items, 2, 2)"
                    class="change_classification rounded-lg"
                    >{{ item.items[2].type_classification }}
                    <feather-icon
                      id="working_hours"
                      icon="InfoIcon"
                      style="margin-left: 5px"
                    />
                  </span>
                </template>
              </span>
            </template>
            <template v-else>
              <img
                :src="require('@/assets/images/icons/equifax.png')"
                width="30px"
                height="30px"
                class="custom-bg-logo"
                alt="Equifax"
              />
            </template>
          </div>
          <ul class="list-unstyled mt-50" v-if="item.showDetail">
            <li
              v-for="(key, index) in keyAccounts"
              :key="index"
              class="custom-heigh-detail"
              :id="`text_equifax_${index}`"
            >
              <template v-if="!!item.items[2][key]">
                <template v-if="fieldTypeMoney.includes(key)">
                  <template v-if="item.items[2][key] != '-'">
                    {{ item.items[2][key] | formatMoney }}
                  </template>
                  <template v-else>-</template>
                </template>
                <template v-else>
                  {{
                    item.items[2][key].length > maxLetter
                      ? `${item.items[2][key].slice(0, maxLetter)}...`
                      : item.items[2][key]
                  }}
                </template>
                <!--tooltip only greater than 'maxLetter'-->
                <b-tooltip
                  v-if="item.items[2][key].length > maxLetter"
                  :target="`text_equifax_${index}`"
                >
                  {{ item.items[2][key] }}
                </b-tooltip>
              </template>
              <template v-else>-</template>
            </li>
          </ul>
        </template>
        <template #cell(detail)="{ item }">
          <div
            class="d-flex justify-content-center align-items-center custom-main-td-height"
          >
            <feather-icon
              icon="ChevronDownIcon"
              size="20"
              class="cursor-pointer hover-me hover-me-primary"
              :class="{ 'rotate-180': item.showDetail }"
              @click="item.showDetail = !item.showDetail"
            />
          </div>
          <ul class="list-unstyled mt-50" v-if="item.showDetail">
            <li
              v-for="(item, index) in textObject.length"
              :key="index"
              class="custom-heigh-detail"
            >
              <!-- is empty -->
            </li>
          </ul>
        </template>
      </b-table>
    </filter-slot>
  </div>
</template>
<script>
import fields from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/data/detailsAccounts.field";
export default {
  name: "RemovedTab",
  props: {
    dataAccounts: {
      require: true,
      type: Array,
    },
  },
  computed: {
    keyAccounts() {
      return Object.keys(this.showKeysDetails) || [];
    },
    textObject() {
      return Object.values(this.showKeysDetails) || [];
    },
  },
  data() {
    return {
      fieldTypeMoney: ["balance", "h_credit", "monthly_payment"],
      filterSearch: {
        type: "input",
        inputType: "text",
        placeholder: "search by account...",
        model: "",
      },
      items: [],
      fields,
      textColor: {
        1: "#3498DB",
        2: "#1ABC9C",
        3: "#8E44AD",
        4: "#E74C3C",
        5: "#F1C40F",
        6: "#F39C12",
        7: "#95A5A6",
        8: "#34495E",
        9: "#BA4A00",
        10: "#7F8C8D",
      },
      showKeysDetails: {
        creditor_name: "Account Name",
        account_number: "Account",
        account_type: "Type",
        account_type_detail: "Type detail",
        bureau_code: "Bureau code",
        account_status: "Account status",
        monthly_payment: "Monthly",
        date_opened: "Date opened",
        balance: "Balance",
        comments: "Comments",
        n_months: "No. Of Months (Terms)",
        credit_limit: "Credit Limit",
        payment_status: "Payment Status",
        last_payment: "Last Payment Status",
        h_credit: "Highest Credit",
      },
      maxLetter: 30,
      paginate: {
        currentPage: 1,
        perPage: 50,
        totalRows: 0,
        startPage: 0,
        toPage: 0,
      },
      itemsPaginada: [],
      classClassification: {
        HIGH: "success",
        MEDIUM: "warning",
        LOW: "danger",
      },
    };
  },
  watch: {
    dataAccounts: {
      handler(value) {
        if (value && value.length > 0) {
          this.getListAccounts(value);
        } else {
          this.items = [];
        }
      },
      deep: true,
      immediate: true,
    },
    "paginate.perPage": {
      handler() {
        this.$nextTick(this.showData);
      },
    },
    "paginate.currentPage": {
      handler() {
        this.$nextTick(this.showData);
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    async getListAccounts(data) {
      try {
        const newData = data.map((item) => {
          const bureaus = item.items || [];
          let firstBureau = bureaus.find((x) => x.id != "-");
          return {
            creditor_name: firstBureau ? firstBureau.creditor_name : "",
            account_number: firstBureau ? firstBureau.account_number : "",
            classification_ncr: firstBureau
              ? firstBureau.classification_ncr
              : "",
            classification_ncr_id: firstBureau
              ? firstBureau.classification_ncr_id
              : "",
            analysis_account_id: firstBureau
              ? firstBureau.analysis_account_id
              : "",
            items: bureaus,
            showDetail: false,
            deleted_at:item.deleted_at
          };
        });
        this.items = newData;
      } catch (error) {
        this.showErrorSwal(error);
        console.log("error in getListAccounts: ", error);
      } finally {
        this.removePreloader();
      }
    },
    showData() {
      this.paginate.totalRows = this.items.length;
      this.showRange();
      let { currentPage, perPage } = this.paginate;
      const startIndex = (currentPage - 1) * perPage;
      const endIndex = startIndex + perPage;
      const pageData = this.items.slice(startIndex, endIndex);

      this.paginate.currentPage = currentPage;
      this.itemsPaginada = pageData;
    },
    showRange() {
      let { currentPage, perPage } = this.paginate;
      const startIndex = (currentPage - 1) * perPage + 1;
      const endIndex = Math.min(currentPage * perPage, this.items.length);
      const totalEntries = this.items.length;
      this.paginate.toPage = endIndex;
      this.paginate.startPage = startIndex;
    },
    colorBadge(items, position, property, classCss = "custom-badge-") {
      let props = { 1: "type_classification_origin", 2: "type_classification" };
      let color = this.classClassification[items[position][props[property]]];
      return `${classCss}${color}`;
    },
    equalsClassification(items, position) {
      return (
        items[position].type_classification_origin !=
        items[position].type_classification
      );
    },
  },

  async mounted() {
    this.$nextTick(this.showData);
  },
};
</script>
<style scoped>
.custom-bg-logo {
  opacity: 0.3 !important;
}
.w-badge {
  width: 120px;
  position: relative;
  text-align: left;
}
.custom-list {
  max-height: 500px !important;
  overflow-y: auto !important;
}
.hover-me-primary {
  transition: 0.4s ease-in-out !important;
}
.hover-me-primary:hover {
  color: #007deb !important;
}
.custom-table,
.custom-table-dark {
  border-radius: 10px !important;
}
.custom-table >>> thead tr th,
.custom-table-dark >>> thead tr th {
  background-color: #0090e7 !important;
}
.custom-table >>> tbody tr {
  background-color: white !important;
}
.custom-bg-gray {
  background-color: #fafafa !important;
}
.custom-bg-gray-dark {
  background-color: #2f2f31 !important;
}
.custom-heigh {
  height: 450px !important;
  overflow: auto;
}
.custom-main-td-height {
  height: 80px;
  display: flex;
  align-items: center !important;
}
.custom-heigh-detail {
  width: 100% !important;
  height: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #2f2f31 !important;
}
.custom-badge-success {
  color: white !important;
  font-weight: bolder !important;
  background-color: #00f380 !important;
}
.custom-badge-warning {
  color: white !important;
  background-color: #ffaf00 !important;
}
.custom-badge-danger {
  color: white !important;
  font-weight: bolder !important;
  background-color: #fe0000 !important;
}
.change_classification {
  width: 100px;
  height: 25px;
  text-align: center;
  position: absolute;
  bottom: 70%;
  left: 70%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-main-td-height.deleted_at::after {
  content: "DELETED";
  width: 82px;
  height: 22px;
  background: red;
  position: absolute;
  border-radius: 10px;
  text-align: center;
  font-weight: bold;
  color: #fff;
  bottom: 39%;
  left:100%;
}
.rotate-180 {
  transform: rotate(180deg) scale(1.3);
  color: #007DEB !important;
}
</style>