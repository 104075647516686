<template>
  <div>
    <b-row>
      <b-col>
        <b-overlay
          :show="loading"
          rounded="sm"
          :bg-color="isDarkSkin && loading ? 'bg-transparent' : ''"
        >
          <b-card :style="{ height: loading ? '469.2px' : '' }">
            <b-card-title class="text-uppercase" style="font-weight: bold"
              >Comparison Between Months</b-card-title
            >
            <hr
              class="m-0 mb-2"
              style="border: 1px solid #0090e7; width: 100%"
            />
            <b-card-text>
              <vueapexchart
                v-if="series.length > 0"
                height="350"
                :options="options"
                :series="series"
                type="bar"
              />
            </b-card-text>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <ModalEfficiency
      :dataAnalyzed="dataAnalyzed"
      v-if="dataAnalyzed"
      @close="dataAnalyzed = null"
      :nameArea="nameArea"
      :typeGraphic="'BYMONTHS'"
    >
      <template #filters>
        <ShowFiltersAnalysisCR :filters="filters" />
      </template>
    </ModalEfficiency>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import ShowFiltersAnalysisCR from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/ShowFiltersAnalysisCR.vue";
import ModalEfficiency from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/Efficiency/modals/ModalEfficiency.vue";
export default {
  name: "ByMonth",
  components: {
    vueapexchart: VueApexCharts,
    ModalEfficiency,
    ShowFiltersAnalysisCR,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    graphicMonths: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dataAnalyzed: null,
      graphicMonthOptions: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: (event, chartContext, config) =>
              this.openModal(event, chartContext, config),
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
        },
        colors: ["#0090e7", "#ff9f43"],
        stroke: {
          width: [0, 0],
        },
        plotOptions: {
          bar: {
            endingShape: "rounded",
            isFunnel3d: true,
            borderRadiusApplication: "around", // "around" / "end"
            borderRadiusWhenStacked: "all", // "all"/"last"
            columnWidth: "60%",
            horizontal: false,
            borderRadius: 0,
            colors: {
              ranges: [
                {
                  from: 0,
                  to: 0,
                  color: undefined,
                },
              ],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 10,
            },
            dataLabels: {
              position: "center", // top, center, bottom
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          categories: [],
          axisBorder: {
            show: true,
          },
          labels: {
            formatter: (value) => {
              if (isNaN(Number(value))) {
                return value.substring(0, 3).toUpperCase();
              } else {
                return value;
              }
            },
          },
        },
        yaxis: {
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
        },
        tooltip: {
          shared: false,
          intersect: true,
          x: {
            show: false,
          },
        },
        legend: {
          horizontalAlign: "left",
          offsetX: 40,
        },
        grid: {
          show: true,
          strokeDashArray: 1,
        },
        theme: {},
        fill: {
          opacity: 0.9,
          type: "gradient",
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 0.3,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: [],
          },
        },
      },
      series: [],
      nameArea: "",
    };
  },
  computed: {
    options() {
      return this.graphicMonthOptions;
    },
  },
  watch: {
    graphicMonths: {
      handler(value) {
        if (Object.keys(value).length > 0) {
          this.$nextTick(this.setData(value));
        } else {
          this.series = [];
        }
      },
      deep: true,
      immediate: true,
    },
    isDarkSkin(value) {
      this.changeTheme(value);
    },
  },
  methods: {
    setData(data) {
      this.changeTheme(this.isDarkSkin);
      this.graphicMonthOptions.xaxis.categories = data.xaxis;
      this.series = data.series;
    },
    openModal(event, chartContext, config) {
      let column = { 0: "success", 1: "observed" };
      console.log({ event, chartContext, config });
      const { dataPointIndex, seriesIndex } = config;
      const { dataMonths } = this.graphicMonths;
      let data_analyzed = dataMonths.find(
        (x) => x.monthNum === dataPointIndex + 1
      );
      if (data_analyzed) {
        this.nameArea = column[seriesIndex].toUpperCase();
        data_analyzed = data_analyzed[column[seriesIndex]].data_analyzed;
        this.dataAnalyzed = data_analyzed;
      }
    },
    changeTheme(value) {
      this.graphicMonthOptions.theme = {
        mode: value ? "dark" : "light",
      };
    },
  },
};
</script>