<template>
  <b-modal v-model="showModal" size="xl" class="" @hidden="closeModal" hide-footer>
    <template #modal-title>
      <h5 class="text-uppercase text-white font-weight-bolder m-0 py-50">
        <tabler-icon
          icon="BuildingBankIcon"
          size="18"
          class="mr-1"
        ></tabler-icon>
        RESULT OF ANALYSIS
      </h5>
    </template>
    <b-container fluid class="py-0">
      <b-row class="pt-2 m-0">
        <b-col cols="12" lg="6" class="my-50 px-0">
          <div
            class="p-1 bg-light-primary rounded d-flex justify-content-start align-items-center mr-0 mr-lg-1"
          >
            <strong
              class="d-flex justify-content-center align-items-center text-uppercase border-right-primary pr-1 mr-1"
            >
              <feather-icon icon="UserIcon" size="15" class="mr-50" />
              Lead Name
            </strong>
            <span class="text-uppercase"> {{ dataNcrRequest.lead_name }}</span>
          </div>
        </b-col>
        <b-col cols="12" lg="6" class="mt-50 px-0">
          <div
            class="p-1 bg-light-primary rounded d-flex justify-content-start align-items-center ml-0 ml-lg-1"
          >
            <strong
              class="d-inline-flex justify-content-center align-items-center w-auto text-uppercase border-right-primary pr-1 mr-1"
            >
              <feather-icon icon="UserIcon" size="15" class="mr-50" />
              CR Obtained by
            </strong>
            <span class="text-uppercase">
              {{ dataNcrRequest.created_by }} ({{
                dataNcrRequest.created_at | myGlobalDay
              }})</span
            >
          </div>
        </b-col>
        <b-col cols="12" lg="6" class="my-50 px-0">
          <div
            class="p-1 bg-light-primary rounded d-flex justify-content-start align-items-center mr-0 mr-lg-1"
          >
            <strong
              class="d-flex justify-content-center align-items-center text-uppercase border-right-primary pr-1 mr-1"
            >
              <amg-icon icon="FilePdfIcon" size="15" class="mr-50" />
              Show CR
            </strong>
            <span class="text-uppercase"> {{ dataNcrRequest.source }} <feather-icon @click="showPdfNcrRequest" style="cursor:pointer;" icon="EyeIcon" size="20"/></span>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="mt-3">
          <b-tabs
            pills
            lazy
            active-nav-item-class="border-primary"
            class="w-100 m-0 p-0 mb-0"
          >
            <b-tab
              v-if="tabValid.remove"
              :active="typeView === 'REMOVE' && tabValid.remove"
              @click="typeView = 'REMOVE'"
              :title-link-class="[bgTabsNavs, 'px-3']"
              class="m-0 p-0"
            >
              <template #title>
                FOR REMOVE
                <span v-if="totalItemsRemove > 0" class="ml-4">
                  <feather-icon
                    icon
                    :badge="
                      totalItemsRemove +
                      (totalItemsRemove == 1 ? ' item' : ' items')
                    "
                    badge-classes="badge-danger"
                  />
                </span>
              </template>
            </b-tab>
            <b-tab
              v-if="tabValid.settlement"
              :active="typeView === 'SETTLEMENTS' && tabValid.settlement"
              @click="typeView = 'SETTLEMENTS'"
              :title-link-class="[bgTabsNavs, 'px-3']"
              class="m-0"
            >
              <template #title>
                FOR SETTLEMENT
                <span v-if="totalItemsNegotiate > 0" class="ml-4">
                  <feather-icon
                    icon
                    :badge="
                      totalItemsNegotiate +
                      (totalItemsNegotiate == 1 ? ' acc' : ' accs')
                    "
                    badge-classes="badge-danger"
                  />
                </span>
              </template>
            </b-tab>
          </b-tabs>
          <b-card
            no-body
            class="w-100 m-0 pb-0 pt-2 px-2"
            :class="{
              'bg-dark': isDarkSkin,
              'custom-bg': !isDarkSkin,
              'border-primary custom-card': true,
            }"
          >
            <b-card-text>
              <SettlementTab
                v-if="typeView == 'SETTLEMENTS' && tabValid.settlement"
                :items="dataResult.settlement"
              />
              <RemovedTab
                v-if="typeView === 'REMOVE' && tabValid.remove"
                :dataAccounts="dataResult.removed"
              />
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import RemovedTab from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/Efficiency/modals/tab/RemovedTab.vue";
import SettlementTab from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/Efficiency/modals/tab/SettlementTab.vue";
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service";

export default {
  name: "ModalTabAnalysisResult",
  props: {
    dataResult: {
      type: Object,
      required: true,
    },
    dataNcrRequest: {
      type: Object,
      required: true,
    },
    tab: {
      type: String,
      required: true,
    },
  },
  components: {
    SettlementTab,
    RemovedTab,
  },
  data() {
    return {
      showModal: false,
      typeView: "REMOVE",
    };
  },
  computed: {
    tabValid() {
      let tabValid = {
        remove: false,
        settlement: false,
      };
      switch (this.tab) {
        case "GENERAL":
        case "BYMONTHS":
          tabValid.remove = true;
          tabValid.settlement = true;
          this.typeView = "REMOVE";
          break;
        case "FOR_REMOVED":
        case "BY_ACCOUNT_FOR_REMOVE":
          tabValid.remove = true;
          this.typeView = "REMOVE";
          break;
        case "FOR_SETTLEMENT":
        case "BY_ACCOUNT_FOR_SETTLEMENT":
          this.typeView = "SETTLEMENTS";
          tabValid.settlement = true;
          break;
      }
      return tabValid;
    },
    totalItemsRemove() {
      let arrayRemove = [...this.dataResult.removed];
      arrayRemove = arrayRemove.map((x) => {
        return x.items.filter((x) => x.creditor_name != "-").length;
      });
      return arrayRemove.reduce((a, b) => a + b, 0);
    },
    totalItemsNegotiate() {
      return [...this.dataResult.settlement].length;
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    async showPdfNcrRequest() {
      try {
        const { url } = await NcrRequestService.getPdfNcrRequest({
          ncrRequestId: this.dataNcrRequest.ncr_request_id,
        });
        window.open(url, "_blank");
      } catch (ex) {}
    },
  },
  mounted() {
    this.showModal = true;
  },
};
</script>
<style scoped>
.custom-card {
  transform: translateY(-15px);
  border-radius: 0px !important;
}
</style>