export default [
    {
        key: "lead_name",
        label: "Client",
        class: "text-left",
        visible: true,
    },
    {
        key: "type_ncr",
        label: "type",
        class: "text-center",
        visible: true,
    },
    {
        key: "departament",
        label: "module",
        class: "text-center",
        visible: true,
    },
    {
        key: "ncr_status",
        label: "Status",
        class: "text-center",
        visible: false,
    },
    {
        key: "description",
        label: "Description",
        class: "text-center",
        thClass: "px-1",
        visible: false,
    },
    {
        key: "charge",
        label: "Charge",
        class: "text-center",
        visible: true,
    },
    {
        key: "analysis",
        label: "analysis CR",
        class: "text-center",
        thClass: "px-0",
        visible: true,
    },
    {
        key: "requested_by",
        label: "Requested by",
        class: "text-center",
        visible: true,
    },
    {
        key: "obtained_by",
        label: "Obtained by",
        class: "text-center",
        visible: true,
    }
];