<template>
  <div id="dashboardAnalysisNcrLead">
    <MainFilter @selectedFilters="selectedFilters" :isLoading="loading" />
    <AnalysisEfficiencyVue
      :loading="loading"
      :dataGraphics="analysisEffficiency"
      :filters="filtersModal"
    />
    <ByAccountType
      :byAccountType="byAccountType"
      :loading="loading"
      :filters="filtersModal"
    />
    <ByMonth
      :graphicMonths="graphicMonths"
      :loading="loading"
      :filters="filtersModal"
    />
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import AnalysisEfficiencyVue from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/Efficiency/AnalysisEfficiency.vue";
import ByAccountType from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/Efficiency/ByAccountType.vue";
import ByMonth from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/Efficiency/ByMonth.vue";
import serviceReportLead from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/Efficiency/services/service-report.lead";
import MainFilter from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/commons/MainFilter.vue";
export default {
  name: "DashboardEfficiency",
  components: {
    vueapexchart: VueApexCharts,
    AnalysisEfficiencyVue,
    ByAccountType,
    ByMonth,
    MainFilter,
  },
  data() {
    return {
      analysisEffficiency: {},
      byAccountType: {
        remove: [],
        negotiate: [],
      },
      graphicMonths: {},
      loading: false,
      filtersModal: {},
    };
  },
  methods: {
    async selectedFilters(filters) {
      try {
        this.filtersModal = filters;
        this.analysisEffficiency = {};
        this.graphicMonths = {};
        this.loading = true;
        const { type, sourceOption, date } = filters;
        const { year, month } = date;
        let _filters = {
          type_ncr: type == null ? "ALL" : type,
          source_id: sourceOption == null ? 0 : sourceOption,
          year,
          month,
        };
        let data = await serviceReportLead.getReport(_filters);
        // data = JSON.parse(data);
        this.analysisEffficiency = data.analysis_effficiency;
        this.byAccountType = data.byAccountType;
        this.graphicMonths = data.graphicMonths;
        this.loading = false;
      } catch (ex) {
        console.log(ex);
      }
    },
  },
};
</script>
<style lang="scss">
#dashboardAnalysisNcrLead {
  .apexcharts-theme-dark {
    svg {
      background: transparent !important;
    }
  }
}
</style>