var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"ByAccountTypeReporNcrtLead"}},[_c('b-card',{attrs:{"body-class":"bg-transparent"}},[_c('b-card-title',{staticClass:"text-uppercase",staticStyle:{"font-weight":"bold"}},[_vm._v(" Efficiency by accounts ")]),_c('b-row',[_c('b-col',[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm","bg-color":_vm.isDarkSkin && _vm.loading ? 'bg-transparent' : ''}},[_c('b-card',{ref:"forRemoveCard",attrs:{"body-class":"text-uppercase"}},[_c('b-card-title',{staticClass:"text-uppercase",staticStyle:{"font-weight":"bold"}},[_vm._v("FOR REMOVE")]),_c('hr',{staticClass:"m-0 mb-2",staticStyle:{"border":"1px solid #0090e7","width":"100%"}}),_c('b-card-text',[_c('b-table',{staticClass:"detail-table",class:_vm.isDarkSkin ? 'custom-table-dark' : 'custom-table',attrs:{"items":_vm.byAccountType.remove,"fields":_vm.fields,"sticky-header":"50vh"},scopedSlots:_vm._u([{key:"cell(account_type)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center w-100 py-2 px-2 custom-td-item font-weight-bolder"},[_vm._v(" "+_vm._s(item.account_type)+" ")])]}},{key:"cell(total)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"span span-badge-total",on:{"click":function($event){return _vm.openModalByAccounts(item, 'TOTAL', 'FOR_REMOVED')}}},[_vm._v(_vm._s(item.total))])])]}},{key:"cell(data_success)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"span span-badge-success",class:item.success.total > 0 ? '' : 'no-click',on:{"click":function($event){return _vm.openModalByAccounts(item, 'SUCCESS', 'FOR_REMOVED')}}},[_vm._v(_vm._s(item.success.total))])])]}},{key:"cell(data_observed)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"span span-badge-observed",class:item.observed.total > 0 ? '' : 'no-click',on:{"click":function($event){return _vm.openModalByAccounts(item, 'OBSERVED', 'FOR_REMOVED')}}},[_vm._v(_vm._s(item.observed.total))])])]}},{key:"cell(percentage)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('b-badge',{staticClass:"percentaje",attrs:{"variant":("light-" + (_vm.variantColor(
                        Number(item.percentage)
                      )))}},[_vm._v(" "+_vm._s(item.percentage)+"% ")])],1)]}}])})],1)],1)],1)],1),_c('b-col',[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm","bg-color":_vm.isDarkSkin && _vm.loading ? 'bg-transparent' : ''}},[_c('b-card',{ref:"forSettlement",attrs:{"body-class":"text-uppercase"}},[_c('b-card-title',{staticClass:"text-uppercase",staticStyle:{"font-weight":"bold"}},[_vm._v("for settlement")]),_c('hr',{staticClass:"m-0 mb-2",staticStyle:{"border":"1px solid #0090e7","width":"100%"}}),_c('b-card-text',[_c('b-table',{staticClass:"detail-table",class:_vm.isDarkSkin ? 'custom-table-dark' : 'custom-table',attrs:{"items":_vm.byAccountType.negotiate,"fields":_vm.fields,"sticky-header":"45vh"},scopedSlots:_vm._u([{key:"cell(account_type)",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center w-100 py-2 px-2 custom-td-item font-weight-bolder"},[_vm._v(" "+_vm._s(item.account_type)+" ")])]}},{key:"cell(total)",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"span span-badge-total",on:{"click":function($event){return _vm.openModalByAccounts(item, 'TOTAL', 'NEGOTIATE')}}},[_vm._v(_vm._s(item.total))])])]}},{key:"cell(data_success)",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"span span-badge-success",class:item.success.total > 0 ? '' : 'no-click',on:{"click":function($event){return _vm.openModalByAccounts(item, 'SUCCESS', 'NEGOTIATE')}}},[_vm._v(_vm._s(item.success.total))])])]}},{key:"cell(data_observed)",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"span span-badge-observed",class:item.observed.total > 0 ? '' : 'no-click',on:{"click":function($event){return _vm.openModalByAccounts(item, 'OBSERVED', 'NEGOTIATE')}}},[_vm._v(_vm._s(item.observed.total))])])]}},{key:"cell(percentage)",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('b-badge',{staticClass:"percentaje",attrs:{"variant":("light-" + (_vm.variantColor(
                        Number(item.percentage)
                      )))}},[_vm._v(" "+_vm._s(item.percentage)+"% ")])],1)]}}])})],1)],1)],1)],1)],1)],1),(_vm.modal.efficiency.dataAnalyzed && _vm.modal.show)?_c('ModalEfficiencyVue',{attrs:{"dataAnalyzed":_vm.modal.efficiency.dataAnalyzed,"typeGraphic":_vm.modal.efficiency.typeGraphic,"nameArea":_vm.modal.efficiency.nameArea,"detailsByAccount":_vm.detailsByAccount,"analysisTotales":_vm.modal.efficiency.analysisTotales,"accountType":_vm.modal.account_type},on:{"close":function($event){return _vm.closeModalEfficiency()}},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('ShowFiltersAnalysisCR',{attrs:{"filters":_vm.filters}})]},proxy:true}],null,false,2991641508)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }