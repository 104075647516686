export default [
  // {
  //     key: 'lead_name',
  //     label: 'client',
  //     visible: true,
  //     thClass: 'text-left',
  //     tdClass: 'text-left ',
  // },
  {
      key: 'item',
      label: '#',
      tdClass: 'px-0',
      visible: true
  },
  {
      key: 'accounts',
      label: 'accounts',
      visible: true,
      thClass: 'text-center',
      tdClass: 'p-0 col-2 text-center',
  },
  {
      key: 'classification_ncr',
      label: 'type',
      visible: true,
      thClass: 'text-center',
      tdClass: 'p-0 text-center col-2 position-relative',
  },
  {
      key: 'priority',
      label: 'CLASSIFICATION',
      visible: true,
      thClass: 'text-center',
      tdClass: 'p-0 text-center col-1 position-relative'
  },
  {
      key: 'balance',
      label: 'balance',
      visible: true,
      thClass: 'text-center',
      tdClass: 'p-0 text-center'
  },
  {
      key: 'high_credit',
      label: 'high credit',
      visible: true,
      thClass: 'text-center',
      tdClass: 'p-0 text-center'
  },
  {
      key: 'average',
      label: 'average %',
      visible: true,
      thClass: 'text-center',
      tdClass: 'text-center',
      tdClass: 'p-1'
  },
  {
      key: 'detail',
      label: '',
      visible: true,
      thClass: 'text-center',
      tdClass: 'text-center position-relative p-0 position-relative',
  },
]
