import { amgApi } from "@/service/axios";

class ServiceReportLead {

    constructor() {
        this.url = "/specialists/financial-approval/dashboard/report-lead-analysis-ncr";
    }
    async getReport(params) {
        const { data } = await amgApi.post(`${this.url}/get-report`, params);
        return data;
    }
    async getAnalyisisEfficiency(params) {
        const { data } = await amgApi.post(`${this.url}/get-analysis-efficiency`, params);
        return data;
    }
    async getSources(params) {
        const { data } = await amgApi.post(`${this.url}/get-sources`, params);
        return data;
    }
    async getLeads(params) {
        const { data } = await amgApi.post(`${this.url}/get-leads`, params);
        return data;
    }
    async getAnalysisCrTab(params) {
        const { data } = await amgApi.post(`${this.url}/get-analysis-cr-tab`, params);
        return data;
    }
    async getByAccountsAnalysisCr(params) {
        const { data } = await amgApi.post(`${this.url}/get-by-accounts-analysis-cr`, params);
        return data;
    }
}
export default new ServiceReportLead();