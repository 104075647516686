<template>
  <div id="ByAccountTypeReporNcrtLead">
    <b-card body-class="bg-transparent">
      <b-card-title class="text-uppercase" style="font-weight: bold">
        Efficiency by accounts
      </b-card-title>
      <b-row>
        <b-col>
          <b-overlay
            :show="loading"
            rounded="sm"
            :bg-color="isDarkSkin && loading ? 'bg-transparent' : ''"
          >
            <b-card body-class="text-uppercase" ref="forRemoveCard">
              <b-card-title class="text-uppercase" style="font-weight: bold"
                >FOR REMOVE</b-card-title
              >
              <hr
                class="m-0 mb-2"
                style="border: 1px solid #0090e7; width: 100%"
              />
              <b-card-text>
                <b-table
                  :items="byAccountType.remove"
                  :fields="fields"
                  class="detail-table"
                  :class="isDarkSkin ? 'custom-table-dark' : 'custom-table'"
                  sticky-header="50vh"
                >
                  <template #cell(account_type)="{ item }">
                    <div
                      class="d-flex align-items-center w-100 py-2 px-2 custom-td-item font-weight-bolder"
                    >
                      {{ item.account_type }}
                    </div>
                  </template>
                  <template #cell(total)="{ item }">
                    <div class="text-center">
                      <span
                        @click="
                          openModalByAccounts(item, 'TOTAL', 'FOR_REMOVED')
                        "
                        class="span span-badge-total"
                        >{{ item.total }}</span
                      >
                    </div>
                  </template>
                  <template #cell(data_success)="{ item }">
                    <div class="text-center">
                      <span
                        class="span span-badge-success"
                        :class="item.success.total > 0 ? '' : 'no-click'"
                        @click="
                          openModalByAccounts(item, 'SUCCESS', 'FOR_REMOVED')
                        "
                        >{{ item.success.total }}</span
                      >
                    </div>
                  </template>
                  <template #cell(data_observed)="{ item }">
                    <div class="text-center">
                      <span
                        class="span span-badge-observed"
                        :class="item.observed.total > 0 ? '' : 'no-click'"
                        @click="
                          openModalByAccounts(item, 'OBSERVED', 'FOR_REMOVED')
                        "
                        >{{ item.observed.total }}</span
                      >
                    </div>
                  </template>
                  <template #cell(percentage)="{ item }">
                    <div class="text-center">
                      <b-badge
                        :variant="`light-${variantColor(
                          Number(item.percentage)
                        )}`"
                        class="percentaje"
                      >
                        {{ item.percentage }}%
                      </b-badge>
                    </div>
                  </template>
                </b-table>
              </b-card-text>
            </b-card>
          </b-overlay>
        </b-col>
        <b-col>
          <b-overlay
            :show="loading"
            rounded="sm"
            :bg-color="isDarkSkin && loading ? 'bg-transparent' : ''"
          >
            <b-card body-class="text-uppercase" ref="forSettlement">
              <b-card-title class="text-uppercase" style="font-weight: bold"
                >for settlement</b-card-title
              >
              <hr
                class="m-0 mb-2"
                style="border: 1px solid #0090e7; width: 100%"
              />
              <b-card-text>
                <b-table
                  :items="byAccountType.negotiate"
                  :fields="fields"
                  class="detail-table"
                  :class="isDarkSkin ? 'custom-table-dark' : 'custom-table'"
                  sticky-header="45vh"
                >
                  <template #cell(account_type)="{ item }">
                    <div
                      class="d-flex align-items-center w-100 py-2 px-2 custom-td-item font-weight-bolder"
                    >
                      {{ item.account_type }}
                    </div>
                  </template>
                  <template #cell(total)="{ item }">
                    <div class="text-center">
                      <span
                        class="span span-badge-total"
                        @click="openModalByAccounts(item, 'TOTAL', 'NEGOTIATE')"
                        >{{ item.total }}</span
                      >
                    </div>
                  </template>
                  <template #cell(data_success)="{ item }">
                    <div class="text-center">
                      <span
                        class="span span-badge-success"
                        :class="item.success.total > 0 ? '' : 'no-click'"
                        @click="
                          openModalByAccounts(item, 'SUCCESS', 'NEGOTIATE')
                        "
                        >{{ item.success.total }}</span
                      >
                    </div>
                  </template>
                  <template #cell(data_observed)="{ item }">
                    <div class="text-center">
                      <span
                        class="span span-badge-observed"
                        :class="item.observed.total > 0 ? '' : 'no-click'"
                        @click="
                          openModalByAccounts(item, 'OBSERVED', 'NEGOTIATE')
                        "
                        >{{ item.observed.total }}</span
                      >
                    </div>
                  </template>
                  <template #cell(percentage)="{ item }">
                    <div class="text-center">
                      <b-badge
                        :variant="`light-${variantColor(
                          Number(item.percentage)
                        )}`"
                        class="percentaje"
                      >
                        {{ item.percentage }}%
                      </b-badge>
                    </div>
                  </template>
                </b-table>
              </b-card-text>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-card>
    <ModalEfficiencyVue
      :dataAnalyzed="modal.efficiency.dataAnalyzed"
      v-if="modal.efficiency.dataAnalyzed && modal.show"
      @close="closeModalEfficiency()"
      :typeGraphic="modal.efficiency.typeGraphic"
      :nameArea="modal.efficiency.nameArea"
      :detailsByAccount="detailsByAccount"
      :analysisTotales="modal.efficiency.analysisTotales"
      :accountType="modal.account_type"
    >
      <template #filters>
        <ShowFiltersAnalysisCR :filters="filters" />
      </template>
    </ModalEfficiencyVue>
  </div>
</template>
<script>
import ModalEfficiencyVue from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/Efficiency/modals/ModalEfficiency.vue";
import ShowFiltersAnalysisCR from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/ShowFiltersAnalysisCR.vue";
export default {
  name: "ByAccountType",
  components: {
    ModalEfficiencyVue,
    ShowFiltersAnalysisCR,
  },
  props: {
    byAccountType: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "account_type", label: "Account Type", thClass: "text-left" },
        { key: "total", label: "Total", thClass: "text-center" },
        { key: "data_success", label: "Success", thClass: "text-center" },
        { key: "data_observed", label: "Observed", thClass: "text-center" },
        { key: "percentage", label: "%", thClass: "text-center" },
      ],
      modal: {
        show: false,
        efficiency: {
          nameArea: "",
          typeGraphic: "BY_ACCOUNT",
          dataAnalyzed: null,
          analysisTotales: [],
          account_type: null,
        },
      },
      detailsByAccount: [],
    };
  },
  computed: {},
  watch: {
    byAccountType: {
      handler(value) {
        const { remove, negotiate } = value;
        let totalRemove = remove.length;
        let totalNegotiate = negotiate.length;
        if (totalRemove > 0 || totalNegotiate > 0) {
          this.$nextTick(() => {
            const { forRemoveCard, forSettlement } = this.$refs;
            forRemoveCard.style.height = "";
            forSettlement.style.height = "";
            totalRemove = forRemoveCard.offsetHeight;
            totalNegotiate = forSettlement.offsetHeight;
            if (totalNegotiate > totalRemove) {
              this.$refs.forSettlement.style.height = "";
              this.$refs.forRemoveCard.style.height = `${totalNegotiate}px`;
            }
            if (totalRemove > totalNegotiate) {
              this.$refs.forSettlement.style.height = `${totalRemove}px`;
              this.$refs.forRemoveCard.style.height = "";
            }
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    variantColor(value) {
      if (value > 50) {
        return "success";
      } else if (value > 20 && value <= 50) {
        return "warning";
      } else {
        return "danger";
      }
    },
    async openModalByAccounts(item, column, tab) {
      try {
        this.addPreloader();
        if (column != "TOTAL" && item[column.toLowerCase()].total === 0)
          throw new Error("No items");
        let _tab = tab === "NEGOTIATE" ? "negotiate" : "remove";
        const dataFinal = this.getDataIdsByAccounts(item, column, _tab);
        this.modal.efficiency.typeGraphic =
          tab == "NEGOTIATE"
            ? "BY_ACCOUNT_FOR_SETTLEMENT"
            : "BY_ACCOUNT_FOR_REMOVE";
        this.modal.show = true;
        this.modal.account_type = item.account_type;
        this.modal.efficiency.nameArea = column;
        this.modal.efficiency.dataAnalyzed = dataFinal;
        this.detailsByAccount =
          column != "TOTAL" ? item[column.toLowerCase()].items : [];
        if (column === "TOTAL") {
          let x = [
            ...item.success.items_totales,
            ...item.observed.items_totales,
          ];
          const resultado = x.reduce((acc, item) => {
            if (!acc[item.id]) {
              acc[item.id] = [];
            }
            acc[item.id].push(item.total);
            return acc;
          }, {});

          const resultadoFinal = Object.keys(resultado).map((id) => ({
            id: parseInt(id),
            total: resultado[id].reduce((a, b) => a + b, 0),
          }));
          this.modal.efficiency.analysisTotales = resultadoFinal;
        } else {
          this.modal.efficiency.analysisTotales =
            item[column.toLowerCase()].items_totales;
        }
        this.removePreloader();
      } catch (ex) {
        this.removePreloader();
      }
    },
    getDataIdsByAccounts(item, column, tab) {
      const { observed, success, total } = item;
      let dataFinal = [];
      switch (column) {
        case "TOTAL":
          let arraySuccess = [...success.data_analyzed];
          let arrayObserved = [...observed.data_analyzed];
          if (arrayObserved.length > arraySuccess.length) {
            arraySuccess.forEach((item) => {
              let index = arrayObserved.findIndex((obj) => obj.id === item.id);
              if (index != -1) {
                arrayObserved[index][tab] = arrayObserved[index][tab].concat(
                  item.remove.filter(
                    (x) => !arrayObserved[index][tab].includes(x)
                  )
                );
              } else {
                arrayObserved.push(item);
              }
            });
            dataFinal = arrayObserved;
          } else {
            arrayObserved.forEach((obj) => {
              let index = arraySuccess.findIndex((obj1) => obj1.id === obj.id);
              if (index != -1) {
                const arrayConcat = obj.remove.filter(
                  (x) => !arraySuccess[index][tab].includes(x)
                );
                const arr = arraySuccess[index][tab].concat(arrayConcat);
                arraySuccess[index][tab] = arr;
              } else {
                arraySuccess.push(obj);
              }
            });
            dataFinal = arraySuccess;
          }
          break;
        case "SUCCESS":
          dataFinal = success.data_analyzed;
          break;
        case "OBSERVED":
          dataFinal = observed.data_analyzed;
          break;
        default:
          dataFinal = [];
          break;
      }
      return dataFinal;
    },
    closeModalEfficiency() {
      this.modal.show = false;
      this.modal.efficiency.dataAnalyzed = null;
      this.modal.efficiency.nameArea = "";
      this.modal.efficiency.typeGraphic = "";
    },
  },
};
</script>
<style scoped>
* {
  --gray: #717d7e8e;
  --blue: #0090e7;
}
#ByAccountTypeReporNcrtLead .percentaje {
  padding: 0.9rem 0.7rem;
  width: 5.5em;
  font-weight: 900;
  border-radius: 10px;
}
#ByAccountTypeReporNcrtLead .detail-table span {
  position: relative; /* Allows positioning of the pseudo-element */
  font-size: 14px;
}
#ByAccountTypeReporNcrtLead .detail-table span.span {
  padding: 0.668rem 0.7rem;
  border-radius: 0.358rem;
  font-weight: bold;
  width: 77px;
  display: inline-block;
}

#ByAccountTypeReporNcrtLead .detail-table span:hover {
  font-weight: 900;
  cursor: pointer;
}
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th,
.custom-table-dark >>> thead tr th,
.custom-table-dark >>> tfoot tr th {
  background-color: #0090e7 !important;
  border-style: none !important;
  color: white !important;
}
.custom-table >>> thead tr th:first-child,
.custom-table-dark >>> thead tr th:first-child {
  border-top-left-radius: 10px !important;
}
.custom-table >>> thead tr th:last-child,
.custom-table-dark >>> thead tr th:last-child {
  border-top-right-radius: 10px !important;
}
.custom-table >>> tbody tr td .custom-td-item {
  background-color: #fafafa !important;
  height: 70px !important;
}
.custom-table-dark >>> tbody tr td .custom-td-item {
  background-color: transparent !important;
  height: 70px !important;
}
.custom-table >>> tbody tr td,
.custom-table-dark >>> tbody tr td {
  border-style: none !important;
  padding: 3px 0px !important;
}
.custom-table >>> tbody tr:first-child td,
.custom-table-dark >>> tbody tr:first-child td {
  padding-top: 8px !important;
}
.custom-table >>> tbody tr td:first-child .custom-td-item,
.custom-table-dark >>> tbody tr td:first-child .custom-td-item {
  border-radius: 10px 0px 0px 10px !important;
  background-color: #d5effa !important;
}
.custom-table-dark >>> tbody tr td:first-child .custom-td-item {
  border: 1px solid #2980b9 !important;
}
.custom-table >>> tbody tr td:last-child .custom-td-item,
.custom-table-dark >>> tbody tr td:last-child .custom-td-item {
  border-radius: 0px 10px 10px 0px !important;
}
.custom-table-dark >>> tbody tr td .custom-td-item {
  border-top: 1px solid var(--gray) !important;
  border-bottom: 1px solid var(--gray) !important;
}
.custom-table-dark >>> tbody tr td:last-child .custom-td-item {
  border-bottom: 1px solid var(--gray) !important;
}

.custom-table-dark >>> tbody tr td {
  background-color: transparent !important;
}

.custom-table >>> tbody tr td:last-child,
.custom-table-dark >>> tbody tr td:last-child {
  border-left: 2px solid var(--blue) !important;
  border-right: 2px solid var(--blue) !important;
}
.custom-table >>> tbody tr:last-child td:last-child,
.custom-table-dark >>> tbody tr:last-child td:last-child {
  border-bottom: 2px solid var(--blue) !important;
  border-radius: 10px !important;
}
.custom-table-dark >>> tbody tr td:first-child .custom-td-item {
  background-color: #2980b9 !important;
  color: white;
}
.text-underline {
  text-decoration: underline !important;
}
.custom-bg-gray {
  background-color: #fafafa !important;
}
.custom-bg-gray-dark {
  background-color: #2f2f31 !important;
}
.font-size-md {
  font-size: 14px;
}
.span-badge-success {
  background: rgba(0, 114, 231, 0.12);
  color: #0090e7;
}
.span.span-badge-observed {
  background: rgba(255, 159, 67, 0.12);
  color: #ff9f43;
}
.span.span-badge-total {
  background-color: rgba(130, 134, 139, 0.12);
  color: #82868b;
}
.no-click {
  cursor: not-allowed !important;
}
</style>
