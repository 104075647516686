<template>
  <div>
    <filter-slot
      :filter="[]"
      :filter-principal="{}"
      :paginate="paginate"
      :totalRows="paginate.totalRows"
      :start-page="paginate.startPage"
      :to-page="paginate.toPage"
      :no-visible-principal-filter="true"
    >
      <b-table
        ref="listTableSettlement"
        slot="table"
        class="blue-scrollbar custom-table"
        :items="itemsPaginada"
        :fields="visibleFields"
        show-empty
        no-provider-filtering
        striped
        sticky-header="50vh"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(item)="{ index }">
          <div
            class="d-flex justify-content-center align-items-center custom-main-td-height"
          >
            <span>{{ index + 1 }}</span>
          </div>
        </template>
        <template #cell(accounts)="{ item }">
          <div class="text-center py-1">
            <span
              class="align-items-center d-block text-truncate font-size-md font-weight-bolder w-custom"
              style="cursor: default; padding: 0 10px; position: relative"
              v-b-tooltip:hover
              :title="item.negotiate.creditor_name"
            >
              {{ item.negotiate.creditor_name }}
            </span>

            <span
              style="font-size: 13px; cursor: default"
              class="my-1 text-capitalize w-custom"
              :class="isDarkSkin ? 'text-white' : 'text-secondary'"
              v-b-tooltip:hover
              :title="item.negotiate.account_number"
              >Account #: {{ item.negotiate.account_number }}</span
            >
            <span class="deleted_at" v-if="item.negotiate.deleted_at">
              <b-badge variant="danger">DELETED</b-badge>
            </span>
          </div>
        </template>
        <template #row-details="{ item }">
          <b-row class="pt-2 pb-1 px-2 border-top">
            <b-col cols="4" class="px-2 border-right-primary">
              <ul class="list-unstyled px-2">
                <li
                  class="w-100 d-block d-xl-flex my-50 justify-content-between my-xl-0 align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon icon="CircleIcon" class="mr-50" size="5" />
                    Account # :
                  </span>
                  <span class="font-italic font-weight-bolder">{{
                    item.negotiate.account_number
                  }}</span>
                </li>
                <li
                  class="w-100 d-block d-xl-flex my-50 justify-content-between my-xl-0 align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon icon="CircleIcon" class="mr-50" size="5" />
                    Type :
                  </span>
                  <span class="font-italic">{{
                    item.negotiate.account_type
                  }}</span>
                </li>
                <li
                  class="w-100 d-block d-xl-flex my-50 justify-content-between my-xl-0 align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                    ><feather-icon
                      icon="CircleIcon"
                      class="mr-50"
                      size="5"
                    />Type detail :</span
                  >
                  <span class="font-italic">{{
                    item.negotiate.account_type_detail || "-"
                  }}</span>
                </li>
                <li
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon icon="CircleIcon" class="mr-50" size="5" />
                    Bureau code:
                  </span>
                  <span class="font-italic">{{
                    item.negotiate.bureau_code
                  }}</span>
                </li>
              </ul>
            </b-col>
            <b-col cols="4" class="px-2 border-right-primary">
              <ul class="list-unstyled px-2">
                <li
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon
                      icon="CircleIcon"
                      class="mr-50"
                      size="5"
                    />Account Status : </span
                  ><span class="font-italic">{{
                    item.negotiate.account_status
                  }}</span>
                </li>
                <li
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon
                      icon="CircleIcon"
                      class="mr-50"
                      size="5"
                    />Monthly payment : </span
                  ><span class="font-italic"
                    >$ {{ item.negotiate.monthly_payment | currency }}</span
                  >
                </li>
                <li
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon
                      icon="CircleIcon"
                      class="mr-50"
                      size="5"
                    />Date opened : </span
                  ><span class="font-italic">{{
                    item.negotiate.date_opened | myGlobal
                  }}</span>
                </li>
                <li
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon
                      icon="CircleIcon"
                      class="mr-50"
                      size="5"
                    />Comments :
                  </span>
                  <span class="font-italic">{{
                    item.negotiate.comments.length > 35
                      ? item.negotiate.comments.substring(0, 35) + "..."
                      : item.negotiate.comments || "-"
                  }}</span>
                </li>
                <li
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon
                      icon="CircleIcon"
                      class="mr-50"
                      size="5"
                    />Last Payment Status :
                  </span>
                  <span class="font-italic">{{
                    item.negotiate.last_payment | myGlobal
                  }}</span>
                </li>
              </ul>
            </b-col>
            <b-col cols="4" class="px-2">
              <ul class="list-unstyled px-2">
                <li
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon icon="CircleIcon" class="mr-50" size="5" />No.
                    of Months (terms) :
                  </span>
                  <span class="font-italic">{{
                    item.negotiate.n_months || "-"
                  }}</span>
                </li>
                <li
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon
                      icon="CircleIcon"
                      class="mr-50"
                      size="5"
                    />Balance :
                  </span>
                  <span class="font-italic">
                    $ {{ item.negotiate.balance | currency }}</span
                  >
                </li>
                <li
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon
                      icon="CircleIcon"
                      class="mr-50"
                      size="5"
                    />Credit Limit :
                  </span>
                  <span class="font-italic"
                    >${{ item.negotiate.credit_limit | currency }}</span
                  >
                </li>
                <li
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon
                      icon="CircleIcon"
                      class="mr-50"
                      size="5"
                    />Payment Status :
                  </span>
                  <span class="font-italic">{{
                    item.negotiate.payment_status || "-"
                  }}</span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </template>
        <template #cell(classification_ncr)="{ item }">
          <div class="d-flex justify-content-center align-items-center h-100">
            <h5
              class="text-uppercase font-weight-bolder text-center"
              :style="`color: ${
                textColor[item.negotiate.classification_ncr_id]
              }`"
            >
              {{ item.negotiate.classification_ncr }}
            </h5>
          </div>
        </template>

        <template #cell(priority)="{ item }">
          <div
            class="w-100 d-flex justify-content-center align-items-center text-center"
          >
            <div
              class="text-white d-flex justify-content-center align-items-center px-1 py-50 rounded-pill text-uppercase font-weight-bolder"
              :style="`border: 1.2px solid ${
                priorityColor[item.negotiate.priority_origin]
              } !important;color: ${
                priorityColor[item.negotiate.priority_origin]
              } !important;position:relative`"
            >
              {{ item.negotiate.priority_origin }}
              <span
                v-b-tooltip.hover
                title="current classification"
                :style="`border: 1.2px solid ${
                  priorityColor[item.negotiate.priority]
                } !important;background:${
                  priorityColor[item.negotiate.priority]
                };cursor:pointer;`"
                class="priority-badge-negotiate"
                v-if="item.negotiate.priority != item.negotiate.priority_origin"
              >
                {{ item.negotiate.priority }}
                <feather-icon
                  id="working_hours"
                  icon="InfoIcon"
                  style="margin-left: 5px"
                />
              </span>
            </div>
          </div>
        </template>

        <template #cell(balance)="{ item, index }">
          <div
            class="w-100 d-flex justify-content-center align-items-center"
            :id="`balance_${index}`"
          >
            <template v-if="!!duplicyBalance[item.negotiate.balance]">
              <span
                class="custom-squad rounded-sm mr-50"
                :style="`background-color: ${
                  duplicyBalance[item.balance].color
                } !important;`"
              />
              <b-tooltip :target="`balance_${index}`" placement="top">
                {{ duplicyBalance[item.balance].cant }} coincidences
              </b-tooltip>
            </template>
            $ {{ item.negotiate.balance | currency }}
          </div>
        </template>

        <template #cell(high_credit)="{ item }">
          $ {{ showHighCredit(item.negotiate) | currency }}
        </template>

        <template #cell(average)="{ item }">
          <div class="d-flex align-items-center justify-content-center">
            <span
              :class="
                !item.negotiate.average
                  ? 'font-weight-bolder text-warning'
                  : null
              "
              >{{ showAverage(item.negotiate) | currency }} %</span
            >
            <feather-icon
              v-if="!item.negotiate.average"
              icon="AlertCircleIcon"
              class="text-warning ml-50"
              v-b-tooltip.hover.v-warning="'AVERAGE BY DEFAULT'"
            />
          </div>
        </template>

        <template #cell(detail)="{ toggleDetails, detailsShowing }">
          <div
            style="width: 40px !important"
            class="d-flex justify-content-center align-items-center"
          >
            <feather-icon
              icon="ChevronUpIcon"
              class="hover-me cursor-pointer"
              :class="{ 'rotate-180': detailsShowing }"
              size="22"
              @click="toggleDetails"
            />
          </div>
        </template>
        <template #custom-foot>
          <b-tr class="fixed-footer">
            <b-th colspan="3"></b-th>
            <b-th class="text-center custom-font-size text-white">Total</b-th>
            <b-th
              class="text-center font-weight-bolder text-success custom-font-size"
            >
              <span
                class="d-flex justify-content-center align-items-center font-weight-bolder text-white"
              >
                $ {{ totalBalance | currency }}
              </span>
            </b-th>
            <b-th
              class="text-center font-weight-bolder text-success custom-font-size"
            >
              <span
                class="d-flex justify-content-center align-items-center font-weight-bolder text-white"
              >
                $ {{ totalHighCredit | currency }}
              </span>
            </b-th>
            <b-th
              class="text-center font-weight-bolder text-success custom-font-size"
            >
              <span
                class="d-flex justify-content-center align-items-center font-weight-bolder text-white"
              >
                {{ promAverage | currency }} %
              </span>
            </b-th>
            <b-th></b-th>
          </b-tr>
        </template>
      </b-table>
    </filter-slot>
  </div>
</template>
<script>
import Fields from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/settlement/data/fields.js";

export default {
  name: "SettlementTab",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      Fields,
      textColor: {
        1: "#3498DB",
        2: "#1ABC9C",
        3: "#8E44AD",
        4: "#E74C3C",
        5: "#F1C40F",
        6: "#F39C12",
        7: "#95A5A6",
        8: "#34495E",
        9: "#BA4A00",
        10: "#7F8C8D",
      },
      priorityColor: {
        high: "#00F380",
        medium: "#FFAF00",
        low: "#FE0000",
        SETTLE: "#00F380",
        "NEEDS CONFIRMATION": "#FFAF00",
        "NOT SETTLE": "#FE0000",
      },
      duplicyBalance: {},
      paginate: {
        currentPage: 1,
        perPage: 50,
        totalRows: 0,
        startPage: 0,
        toPage: 0,
      },
      itemsPaginada: [],
    };
  },
  computed: {
    visibleFields() {
      this.updateVisibleFields("detail", true);
      return this.Fields.filter((fields) => fields.visible);
    },
    totalBalance() {
      return this.items.reduce((sum, item) => {
        if (item.negotiate.action === "negotiate") {
          sum += parseFloat(item.negotiate.balance);
        }
        return sum;
      }, 0);
    },
    totalHighCredit() {
      return this.items.reduce((sum, item) => {
        if (item.negotiate.action.toLowerCase() === "negotiate") {
          sum += parseFloat(item.negotiate.high_credit);
        }
        return sum;
      }, 0);
    },
    promAverage() {
      const sum = this.items.reduce((sum, item) => {
        if (item.negotiate.action.toLowerCase() === "negotiate") {
          let average = item.negotiate.average;
          if (average == null) average = 60;
          sum += parseFloat(average);
        }
        return sum;
      }, 0);
      const total = this.items.length;
      return total > 0 ? sum / total : null;
    },
  },
  watch: {
    "paginate.perPage": {
      handler() {
        this.$nextTick(this.showData);
      },
    },
    "paginate.currentPage": {
      handler() {
        this.$nextTick(this.showData);
      },
    },
  },
  methods: {
    analyzeBalance(array) {
      const colors = [
        "#EF5350",
        "#45B39D",
        "#F4D03F",
        "#F39C12",
        "#D35400",
        "#EC407A",
        "#AB47BC",
        "#9575CD",
        "#5C6BC0",
        "#42A5F5",
      ];
      const balances = array.map((item) => item.balance);
      const duplicyBalance = balances
        .filter((value, index, array) => {
          return index !== array.findIndex((el) => el === value);
        })
        .reduce((result, item, index) => {
          result[item] = {
            color: colors[index] ? colors[index] : null,
            cant: balances.filter((el) => el === item).length,
          };
          return result;
        }, {});

      this.duplicyBalance = { ...duplicyBalance };
    },
    showHighCredit(item) {
      item.high_credit =
        item.high_credit == null || item.high_credit == "-"
          ? 0
          : item.high_credit;
      return parseFloat(item.high_credit).toFixed(2);
    },
    showAverage(item) {
      if (item.average == null) {
        return 60;
      } else {
        return item.average;
      }
    },
    updateVisibleFields(key, value) {
      let index = this.Fields.findIndex((field) => field.key === key);
      this.Fields[index]["visible"] = value;
    },
    showData() {
      this.paginate.totalRows = this.items.length;
      this.showRange();
      let { currentPage, perPage } = this.paginate;
      const startIndex = (currentPage - 1) * perPage;
      const endIndex = startIndex + perPage;
      const pageData = this.items.slice(startIndex, endIndex);

      this.paginate.currentPage = currentPage;
      this.itemsPaginada = pageData;
    },
    showRange() {
      let { currentPage, perPage } = this.paginate;
      const startIndex = (currentPage - 1) * perPage + 1;
      const endIndex = Math.min(currentPage * perPage, this.items.length);
      const totalEntries = this.items.length;
      this.paginate.toPage = endIndex;
      this.paginate.startPage = startIndex;
    },
  },
  created() {
    this.analyzeBalance(this.items);
  },
  mounted() {
    this.$nextTick(this.showData);
  },
};
</script>
<style scoped>
.w-custom {
  width: 300px;
  max-width: 300px;
}
.fixed-footer {
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: 1px solid #ddd;
  z-index: 9;
}
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th {
  background-color: #0090e7 !important;
  color: white !important;
}
span.deleted_at {
  bottom: 70%;
  left: 75%;
  position: absolute;
}
span.priority-badge-negotiate {
  position: absolute;
  width: 120px;
  font-size: 9px;
  bottom: 69%;
  left: 70%;
  font-weight: bold;
  border-radius: 10px;
  color: #fff;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rotate-180 {
  transform: rotate(180deg) scale(1.3);
  color: #007DEB !important;
}
</style>
