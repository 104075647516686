export default [
    {
        key: 'item',
        label: '#',
        tdClass: 'px-0 py-0',
    },
    {
        key: 'account',
        thClass: 'col-2 text-center',
        tdClass: 'px-0 py-0',
    },
    {
        key: 'type',
        thClass: 'text-center',
        tdClass: 'text-center px-0 py-0'
    },
    {
        key: 'transunion',
        thClass: 'text-center',
        tdClass: 'text-center px-0 py-0'
    },
    {
        key: 'experian',
        thClass: 'text-center',
        tdClass: 'text-center px-0 py-0'
    },
    {
        key: 'equifax',
        thClass: 'text-center',
        tdClass: 'text-center px-0 py-0'
    },
    {
        key: 'detail',
        thClass: 'text-center',
        tdClass: 'text-center px-0 py-0'
    },
];