<template>
  <div class="mt-3">
    <b-row>
      <b-col cols="4">
        <b-overlay
          :show="loading"
          rounded="sm"
          :bg-color="isDarkSkin && loading ? 'bg-transparent' : ''"
        >
          <b-card :style="loading ? cardStyle : ''" ref="cardAnalyzed">
            <b-card-title style="font-weight: bold" class="text-uppercase">
              Total Analyzed Comparison
            </b-card-title>
            <hr
              class="m-0 mb-2"
              style="border: 1px solid #0090e7; width: 100%"
            />
            <b-card-text>
              <vueapexchart
                v-if="graphics.seriesGeneral.length > 0"
                height="300"
                type="donut"
                :options="optionsGeneral"
                :series="graphics.seriesGeneral"
                id="chartPieGeneral"
              />
            </b-card-text>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col cols="4">
        <b-overlay
          :show="loading"
          rounded="sm"
          :bg-color="isDarkSkin && loading ? 'bg-transparent' : ''"
        >
          <b-card :style="loading ? cardStyle : ''" ref="cardForRemove">
            <b-card-title style="font-weight: bold" class="text-uppercase">
              For Remove Comparison
            </b-card-title>
            <hr
              class="m-0 mb-2"
              style="border: 1px solid #0090e7; width: 100%"
            />
            <b-card-text>
              <vueapexchart
                v-if="graphics.seriesForRemoved.length > 0"
                type="bar"
                height="300"
                :series="graphics.seriesForRemoved"
                :options="optionsForRemoved"
              ></vueapexchart>
            </b-card-text>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col cols="4">
        <b-overlay
          :show="loading"
          rounded="sm"
          :bg-color="isDarkSkin && loading ? 'bg-transparent' : ''"
        >
          <b-card :style="loading ? cardStyle : ''" ref="cardForSettlement">
            <b-card-title style="font-weight: bold" class="text-uppercase">
              For Settlement Comparison
            </b-card-title>
            <hr
              class="m-0 mb-2"
              style="border: 1px solid #0090e7; width: 100%"
            />
            <b-card-text>
              <vueapexchart
                v-if="graphics.seriesForSettlement.length > 0"
                type="bar"
                height="300"
                :series="graphics.seriesForSettlement"
                :options="optionsForSettlement"
              ></vueapexchart>
            </b-card-text>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <ModalEfficiencyVue
      :dataAnalyzed="dataAnalyzed"
      v-if="dataAnalyzed"
      @close="dataAnalyzed = null"
      :typeGraphic="nameGraphic"
      :nameArea="nameArea"
    >
      <template #filters>
        <ShowFiltersAnalysisCR :filters="filters" />
      </template>
    </ModalEfficiencyVue>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import ModalEfficiencyVue from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/Efficiency/modals/ModalEfficiency.vue";
import ShowFiltersAnalysisCR from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/ShowFiltersAnalysisCR.vue";

export default {
  name: "AnalysisEfficiency",
  props: {
    dataGraphics: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  components: {
    vueapexchart: VueApexCharts,
    ModalEfficiencyVue,
    ShowFiltersAnalysisCR,
  },
  data() {
    return {
      nameArea: "",
      nameGraphic: "",
      cardStyle: {
        height: "419.2px",
      },
      dataAnalyzed: null,
      chartOptions: {
        chart: {
          type: "donut",
          events: {},
          height: 300,
          selection: {
            enabled: false,
          },
        },
        labels: ["SUCCESS", "OBSERVED"],
        colors: ["#0090e7", "#ff9f43"],
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            const { w, seriesIndex } = opts;
            return w.globals.series[seriesIndex];
          },
          dropShadow: {
            enabled: false,
          },
          style: {
            fontSize: "16px",
            fontWeight: "bold",
            fontFamily: "Helvetica, Arial, sans-serif",
          },
        },
        legend: {
          markers: {
            width: 12,
            height: 12,
            radius: 2,
          },
          formatter: (seriesName, opts) => {
            let total = opts.w.globals.seriesTotals.reduce((a, b) => {
              return a + b;
            }, 0);
            let obj = { OBSERVED: "Observed", SUCCESS: "Success" };
            let val = Number(opts.w.globals.series[opts.seriesIndex]);
            let valor = isNaN(val) ? 0 : val;
            let porcentaje = valor > 0 && total > 0 ? (valor / total) * 100 : 0;
            return `<span><strong >${porcentaje.toFixed(2)}%</strong> ${
              obj[seriesName]
            }</span>
              <small class='d-block'>(${valor} reports)</small>`;
          },
        },
        stroke: {
          show: true,
          curve: "smooth",
          // colors: "#17171A",
          width: 5,
        },
        plotOptions: {
          pie: {
            donut: {
              background: "transparent",
              labels: {
                show: true,
                showAlways: false,
                total: {
                  showAlways: true,
                  show: true,
                  label: "Total Reports",
                  fontSize: "14px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  color: "#37474f",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
                value: {
                  show: true,
                  fontSize: "30px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 400,
                  offsetY: 9,
                  formatter: function (val) {
                    return val;
                  },
                },
              },
              borderRadius: 10,
            },
          },
        },
        theme: {},
        fill: {
          opacity: 0.9,
          type: "gradient",
          gradient: {
            shade: "light",
            type: "diagonal2",
            shadeIntensity: 0.4,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: [],
          },
        },
      },
      chartEventsPieGeneral: {
        dataPointSelection: (event, chartContext, config) =>
          this.pointSelectionPieChart(event, chartContext, config, "GENERAL"),
      },
      chartEventsPieForRemoved: {
        dataPointSelection: (event, chartContext, config) =>
          this.pointSelectionPieChart(
            event,
            chartContext,
            config,
            "FOR_REMOVED"
          ),
      },
      chartEventsPieForSettlement: {
        dataPointSelection: (event, chartContext, config) =>
          this.pointSelectionPieChart(
            event,
            chartContext,
            config,
            "FOR_SETTLEMENT"
          ),
      },
      graphics: {
        seriesGeneral: [],
        seriesForRemoved: [],
        seriesForSettlement: [],
      },
      typeArea: ["SUCCESS", "OBSERVED"],
      typeGraphic: {
        GENERAL: "graphicTotales",
        FOR_REMOVED: "graphicForRemoved",
        FOR_SETTLEMENT: "graphicForSettlement",
      },
      chartOptionsRemoveNegotiate: {
        chart: {
          type: "bar",
          height: 300,
          toolbar: {
            show: false,
          },
          events: {},
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            distributed: true,
            horizontal: false,
            columnWidth: "85%",
            dataLabels: {
              position: "center",
            },
          },
        },
        fill: {
          opacity: 0.9,
          type: "gradient",
          gradient: {
            shade: "light",
            type: "diagonal2",
            shadeIntensity: 0.3,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: [],
          },
        },
        stroke: {
          width: [0, 0],
        },
        colors: ["#0090e7", "#ff9f43"],
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#fff"],
          },
          formatter: function (val, opt) {
            return val;
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
          },
        },
        xaxis: {
          categories: ["SUCCESS", "OBSERVED"],
        },
        yaxis: {
          labels: {
            show: true,
          },
        },
        tooltip: {
          shared: false,
          intersect: true,
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: (val, opts) => {
                return opts.w.globals.labels[opts.dataPointIndex] + ":";
              },
            },
          },
        },
        legend: {
          position: "right",
          offsetY: 4,
          formatter: (seriesName, opts) => {
            let total = opts.w.globals.seriesTotals.reduce((a, b) => {
              return a + b;
            }, 0);
            let obj = { OBSERVED: "Observed", SUCCESS: "Success" };
            let val = Number(opts.w.globals.series[0][opts.seriesIndex]);
            let valor = isNaN(val) ? 0 : val;
            let porcentaje = valor > 0 && total > 0 ? (valor / total) * 100 : 0;
            return `<span><strong >${porcentaje.toFixed(2)}%</strong> ${
              obj[seriesName]
            }</span>
              <small class='d-block'>(${valor} reports)</small>`;
          },
        },
        theme: {},
      },
      seriesRemoved: [],
    };
  },
  computed: {
    optionsForRemoved() {
      return {
        ...this.chartOptionsRemoveNegotiate,
        chart: {
          ...this.chartOptionsRemoveNegotiate.chart,
          events: {
            ...this.chartEventsPieForRemoved,
          },
        },
      };
    },
    optionsForSettlement() {
      return {
        ...this.chartOptionsRemoveNegotiate,
        chart: {
          ...this.chartOptionsRemoveNegotiate.chart,
          events: {
            ...this.chartEventsPieForSettlement,
          },
        },
      };
    },
    optionsGeneral() {
      return {
        ...this.chartOptions,
        chart: {
          ...this.chartOptions.chart,
          events: {
            ...this.chartEventsPieGeneral,
          },
        },
      };
    },
  },
  watch: {
    isDarkSkin(value) {
      this.changeTheme(value);
    },
    dataGraphics: {
      handler(value) {
        if (Object.keys(value).length > 0) {
          this.setData(value);
        } else {
          this.graphics.seriesForRemoved = [];
          this.graphics.seriesForSettlement = [];
          this.graphics.seriesGeneral = [];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    changeTheme(value) {
      this.chartOptions.stroke = {
        ...this.chartOptions.stroke,
        colors: value ? "#17171A" : "#FFF",
      };
      this.chartOptions.plotOptions.pie.donut.labels.total.color = value
        ? "#FFF"
        : "#37474f";
      this.chartOptions.theme = {
        mode: value ? "dark" : "light",
      };
      this.chartOptionsRemoveNegotiate.theme = {
        mode: value ? "dark" : "light",
      };
    },
    setData(data) {
      this.changeTheme(this.isDarkSkin);
      const { graphicForRemoved, graphicForSettlement, graphicTotales } = data;
      this.graphics.seriesGeneral = [
        graphicTotales.success.total,
        graphicTotales.observed.total,
      ];

      this.graphics.seriesForRemoved = [
        {
          data: [
            graphicForRemoved.success.total,
            graphicForRemoved.observed.total,
          ],
        },
      ];

      this.graphics.seriesForSettlement = [
        {
          data: [
            graphicForSettlement.success.total,
            graphicForSettlement.observed.total,
          ],
        },
      ];
      this.$nextTick(() => {
        this.changeHeightCard();
      });
    },
    async pointSelectionPieChart(event, chartContext, config, type) {
      const { dataPointIndex, selectedDataPoints } = config;
      if (selectedDataPoints[0].length > 0) {
        this.nameGraphic = type;
        const typeArea = this.typeArea[dataPointIndex];
        this.nameArea = typeArea;
        await this.getByTypeArea(
          typeArea,
          this.dataGraphics[this.typeGraphic[type]]
        );
      }
    },
    async getByTypeArea(typeArea, data) {
      const { data_analyzed } = data[typeArea.toLowerCase()];
      this.dataAnalyzed = data_analyzed;
    },
    changeHeightCard() {
      let { cardAnalyzed, cardForRemove, cardForSettlement } = this.$refs;
      let { seriesForRemoved, seriesForSettlement } = this.graphics;
      const heightCardGeneral = cardAnalyzed.offsetHeight;
      const heightCardRemove = cardForRemove.offsetHeight;
      if (heightCardRemove > heightCardGeneral) {
        cardAnalyzed.style.height = `${heightCardRemove}px`;
      }
    },
  },
};
</script>