var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-slot',{attrs:{"filter":[],"filter-principal":_vm.filterSearch,"paginate":_vm.paginate,"totalRows":_vm.paginate.totalRows,"start-page":_vm.paginate.startPage,"to-page":_vm.paginate.toPage,"no-visible-principal-filter":true}},[_c('b-table',{ref:"listTableSettlement",staticClass:"shadow-none",class:_vm.isDarkSkin ? 'custom-table-dark' : 'custom-table',attrs:{"slot":"table","items":_vm.itemsPaginada,"fields":_vm.fields,"show-empty":"","no-provider-filtering":"","striped":"","sticky-header":"50vh","head-row-variant":'grid-custom-tickets-header'},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(item)",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center align-items-center custom-main-td-height"},[_c('span',[_vm._v(_vm._s(index + 1))])]),(item.showDetail)?_c('ul',{staticClass:"list-unstyled mt-50"},_vm._l((_vm.textObject.length),function(item,index){return _c('li',{key:index,staticClass:"custom-heigh-detail"})}),0):_vm._e()]}},{key:"cell(account)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center align-items-center custom-main-td-height",class:item.deleted_at ? 'deleted_at' : '',staticStyle:{"max-width":"200px","position":"relative"}},[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('strong',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(item.creditor_name),expression:"item.creditor_name",modifiers:{"hover":true,"top":true}}],staticClass:"text-truncate"},[_vm._v(_vm._s(item.creditor_name))]),_c('span',[_vm._v("Account #:"+_vm._s(item.account_number))])])]),(item.showDetail)?_c('ul',{staticClass:"list-unstyled mt-50"},_vm._l((_vm.textObject),function(item,index){return _c('li',{key:index,staticClass:"custom-heigh-detail"},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e()]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center align-items-center custom-main-td-height"},[_c('span',{style:(("color: " + (_vm.textColor[item.classification_ncr_id])))},[_vm._v(_vm._s(item.classification_ncr))])]),(item.showDetail)?_c('ul',{staticClass:"list-unstyled mt-50"},_vm._l((_vm.textObject.length),function(item,index){return _c('li',{key:index,staticClass:"custom-heigh-detail"})}),0):_vm._e()]}},{key:"cell(transunion)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center align-items-center custom-main-td-height"},[(item.items[0].type_classification != '-')?[_c('span',{staticClass:"px-1 py-50 rounded-lg w-badge",class:_vm.colorBadge(item.items, 0, 1)},[_c('tabler-icon',{attrs:{"size":"20","icon":item.items[0].transunion_result == 1 &&
                  item.type_classification != 'LOW'
                    ? 'CircleCheckIcon'
                    : 'CircleIcon'}}),_vm._v(" "+_vm._s(item.items[0].type_classification_origin)+" "),(_vm.equalsClassification(item.items, 0))?[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"change_classification rounded-lg",class:_vm.colorBadge(item.items, 0, 2),attrs:{"title":"current classification"}},[_vm._v(_vm._s(item.items[0].type_classification)+" "),_c('feather-icon',{staticStyle:{"margin-left":"5px"},attrs:{"id":"working_hours","icon":"InfoIcon"}})],1)]:_vm._e()],2)]:[_c('img',{staticClass:"custom-bg-logo",attrs:{"src":require('@/assets/images/icons/transunion.png'),"width":"30px","height":"30px","alt":"TransUnion"}})]],2),(item.showDetail)?_c('ul',{staticClass:"list-unstyled mt-50"},_vm._l((_vm.keyAccounts),function(key,index){return _c('li',{key:index,staticClass:"custom-heigh-detail",attrs:{"id":("text_transunion_" + index)}},[(!!item.items[0][key])?[(_vm.fieldTypeMoney.includes(key))?[(item.items[0][key] != '-')?[_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.items[0][key]))+" ")]:[_vm._v("-")]]:[_vm._v(" "+_vm._s(item.items[0][key].length > _vm.maxLetter ? ((item.items[0][key].slice(0, _vm.maxLetter)) + "...") : item.items[0][key])+" ")],(item.items[0][key].length > _vm.maxLetter)?_c('b-tooltip',{attrs:{"target":("text_transunion_" + index)}},[_vm._v(" "+_vm._s(item.items[0][key])+" ")]):_vm._e()]:[_vm._v("-")]],2)}),0):_vm._e()]}},{key:"cell(experian)",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center align-items-center custom-main-td-height"},[(item.items[1].type_classification != '-')?[_c('span',{staticClass:"px-1 py-50 rounded-lg w-badge",class:_vm.colorBadge(item.items, 1, 1)},[_c('tabler-icon',{attrs:{"size":"20","icon":item.items[1].experian_result == 1 &&
                  item.type_classification != 'LOW'
                    ? 'CircleCheckIcon'
                    : 'CircleIcon'}}),_vm._v(" "+_vm._s(item.items[1].type_classification_origin)+" "),(_vm.equalsClassification(item.items, 1))?[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"change_classification rounded-lg",class:_vm.colorBadge(item.items, 1, 2),attrs:{"title":"current classification"}},[_vm._v(_vm._s(item.items[1].type_classification)+" "),_c('feather-icon',{staticStyle:{"margin-left":"5px"},attrs:{"id":"working_hours","icon":"InfoIcon"}})],1)]:_vm._e()],2)]:[_c('img',{staticClass:"custom-bg-logo",attrs:{"src":require('@/assets/images/icons/experian.png'),"width":"30px","height":"30px","alt":"Experian"}})]],2),(item.showDetail)?_c('ul',{staticClass:"list-unstyled mt-50"},_vm._l((_vm.keyAccounts),function(key,index){return _c('li',{key:index,staticClass:"custom-heigh-detail",attrs:{"id":("text_experian_" + index)}},[(!!item.items[1][key])?[(_vm.fieldTypeMoney.includes(key))?[(item.items[1][key] != '-')?[_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.items[1][key]))+" ")]:[_vm._v("-")]]:[_vm._v(" "+_vm._s(item.items[1][key].length > _vm.maxLetter ? ((item.items[1][key].slice(0, _vm.maxLetter)) + "...") : item.items[1][key])+" ")],(item.items[1][key].length > _vm.maxLetter)?_c('b-tooltip',{attrs:{"target":("text_experian_" + index)}},[_vm._v(" "+_vm._s(item.items[1][key])+" ")]):_vm._e()]:[_vm._v("-")]],2)}),0):_vm._e()]}},{key:"cell(equifax)",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center align-items-center custom-main-td-height"},[(item.items[2].type_classification != '-')?[_c('span',{staticClass:"px-1 py-50 rounded-lg w-badge text-left",class:_vm.colorBadge(item.items, 2, 1)},[_c('tabler-icon',{attrs:{"size":"20","icon":item.items[2].equifax_result == 1 &&
                  item.type_classification != 'LOW'
                    ? 'CircleCheckIcon'
                    : 'CircleIcon'}}),_vm._v(" "+_vm._s(item.items[2].type_classification_origin)+" "),(_vm.equalsClassification(item.items, 2))?[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"change_classification rounded-lg",class:_vm.colorBadge(item.items, 2, 2),attrs:{"title":"current classification"}},[_vm._v(_vm._s(item.items[2].type_classification)+" "),_c('feather-icon',{staticStyle:{"margin-left":"5px"},attrs:{"id":"working_hours","icon":"InfoIcon"}})],1)]:_vm._e()],2)]:[_c('img',{staticClass:"custom-bg-logo",attrs:{"src":require('@/assets/images/icons/equifax.png'),"width":"30px","height":"30px","alt":"Equifax"}})]],2),(item.showDetail)?_c('ul',{staticClass:"list-unstyled mt-50"},_vm._l((_vm.keyAccounts),function(key,index){return _c('li',{key:index,staticClass:"custom-heigh-detail",attrs:{"id":("text_equifax_" + index)}},[(!!item.items[2][key])?[(_vm.fieldTypeMoney.includes(key))?[(item.items[2][key] != '-')?[_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.items[2][key]))+" ")]:[_vm._v("-")]]:[_vm._v(" "+_vm._s(item.items[2][key].length > _vm.maxLetter ? ((item.items[2][key].slice(0, _vm.maxLetter)) + "...") : item.items[2][key])+" ")],(item.items[2][key].length > _vm.maxLetter)?_c('b-tooltip',{attrs:{"target":("text_equifax_" + index)}},[_vm._v(" "+_vm._s(item.items[2][key])+" ")]):_vm._e()]:[_vm._v("-")]],2)}),0):_vm._e()]}},{key:"cell(detail)",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center align-items-center custom-main-td-height"},[_c('feather-icon',{staticClass:"cursor-pointer hover-me hover-me-primary",class:{ 'rotate-180': item.showDetail },attrs:{"icon":"ChevronDownIcon","size":"20"},on:{"click":function($event){item.showDetail = !item.showDetail}}})],1),(item.showDetail)?_c('ul',{staticClass:"list-unstyled mt-50"},_vm._l((_vm.textObject.length),function(item,index){return _c('li',{key:index,staticClass:"custom-heigh-detail"})}),0):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }